html, body, #root, .App {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.App {
    align-items: center;
    justify-content: center;
    display: flex;
}

@font-face {
    font-family: Montserrat;
    src: url("../fonts/Montserrat/Montserrat-Medium.ttf");
}

@font-face {
    font-family: Montserrat-light;
    src: url("../fonts/Montserrat/Montserrat-Regular.ttf");
}

* {
    font-family: "Montserrat-light", sans-serif;
    transition: all .2s;
}

h1 {
    font-weight: bolder;
}

a {
    cursor: pointer;
}

.pac-container {
    z-index: 15000;
}